import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'

export class AuthService {
  async getAccessToken (): Promise<string> {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    return accessToken?.toString() ?? ''
  }

  async getUsername (): Promise<string> {
    const { username } = await getCurrentUser()
    return username
  }
}
