import React, { useState } from "react";
import { ChatLambdaRequestBody, NetworkService } from '../services/NetworkService';
import { Feedback, Message } from "../types";
import MessageComponent from "./Message";
import { AuthService } from "../services/AuthService";

interface ThreadProps {
  conversationId: string
  messagesList: Message[]
  feedbackList: Feedback[]
  refreshConversationList: () => void
}

const networkService = new NetworkService()
const authService = new AuthService()

const ThreadComponent: React.FC<ThreadProps> = ({
  conversationId,
  messagesList,
  feedbackList,
  refreshConversationList
}: ThreadProps) => {
  const [isSending, setIsSending] = useState(false);
  const [lastConversationId, setLastConversationId] = useState<string | null>(null)
  const [question, setQuestion] = useState('')
  const [messages, setMessages] = useState(messagesList)
  const [feedback, setFeedback] = useState(feedbackList)

  if (conversationId !== lastConversationId) {
    setLastConversationId(conversationId)
    setQuestion('')
    setMessages(messagesList)
    setFeedback(feedbackList)
  }

  const sendMessage = async (currentMessages: Message[]) => {
    setIsSending(true);
    setQuestion('');
    const requestBody: ChatLambdaRequestBody = {
      conversationId: conversationId,
      username: await authService.getUsername(),
      question,
    };

    const userMessage: Message = {
      content: question,
      role: 'user'
    }

    const newMessageList: Message[] = [
      ...currentMessages,
      userMessage,
    ]

    setMessages([...newMessageList]) // needs to be a different object to re-render

    const responseStream = await networkService.invokeChatLambda(requestBody)
    const reader = responseStream.getReader()
    const decoder = new TextDecoder()
    const statusMessageDelimiter = '\n~\n~\n'
    const toolsMessageDelimiter = '\nX~\nX~\n'
    const assistantMessage: Message = {
      content: '',
      role: 'assistant'
    }
    const toolsMessage: Message = {
      content: '',
      role: 'function'
    }
    const read = async () => {
      const { done, value } = await reader.read()
      if (done) {
        setIsSending(false)
        if (currentMessages.length === 0) {
          refreshConversationList()
        }
        return
      }
      const message = decoder.decode(value)
      if (message.includes(statusMessageDelimiter) || message.includes(toolsMessageDelimiter)) {
        let remainingMessage = message;
        if (message.includes(statusMessageDelimiter)) {
            const parts = message.split(statusMessageDelimiter);
            assistantMessage.statusUpdate = JSON.parse(parts[parts.length - 2]);
            remainingMessage = parts[parts.length - 1];
        }

        if (remainingMessage.includes(toolsMessageDelimiter)) {
            const parts = remainingMessage.split(toolsMessageDelimiter);
            toolsMessage.content = JSON.parse(parts[parts.length - 2]);
            assistantMessage.content += parts[parts.length - 1];
        } else {
            assistantMessage.content += remainingMessage;
        }
      } else {
        assistantMessage.content += message
      }
      if (newMessageList[newMessageList.length - 1]?.role === 'user') {
        newMessageList.push(toolsMessage)
        newMessageList.push(assistantMessage)
      } else {
        newMessageList[newMessageList.length - 2] = toolsMessage
        newMessageList[newMessageList.length - 1] = assistantMessage
      }
      setMessages([...newMessageList]) // needs to be a different object to re-render
      await read()
    }
    read()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      sendMessage(messages)
    }
  };

  const sendFeedback = async (feedbackItem: Feedback) => {
    try {
      await networkService.invokeDispatcherLambda({
        action: 'userFeedback',
        username: await authService.getUsername(),
        conversationId,
        feedback: feedbackItem,
      })
      setFeedback([
        ...feedback,
        feedbackItem
      ])
    } catch (error) {
      // The error is already handled inside invokeDispatcherLambda
    }
  }

  return (
    <div className="h-full">
      {messages.length === 0 ? (
        <div className="p-4 h-full flex flex-col items-center">
          <div className="flex-auto"></div>
          <div className="max-h-[40vh] flex justify-center">
            <img src="/logo.png" alt="Max" className="h-full" />
          </div>
          <div className="my-4 flex-none text-center text-slate-600">
            I am Max, your Trilogy Co-Pilot powered by ESB<br />with access to our repos and documents
          </div>
          <div className="flex-auto"></div>
        </div>
      ) : (
        <div className="p-4">
          {messages.map((message: Message, index: number) => (
            <div className="my-4" key={index}>
              <MessageComponent messageIndex={index} message={message} feedbackList={feedback} sendFeedback={sendFeedback}/>
            </div>
          ))}
        </div>
      )}
      <div className="flex fixed bottom-0 p-4 left-80 right-0">
        <div className="flex w-full h-12 bg-white border border-gray-300 rounded-md focus-within:ring focus-within:border-blue-300">
          <input
            type="text"
            placeholder="Ask Max a question"
            className="w-full px-4 bg-transparent focus:outline-none"
            onKeyDown={handleKeyDown}
            readOnly={isSending}
            onChange={(event) => setQuestion(event.target.value)}
            value={question}
          />
          <button className="px-4 py-2 bg-blue-500 text-white rounded-r-md" onClick={() => sendMessage(messages)} disabled={isSending}>
            {!isSending && <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <path fill="#ffffff" d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/>
            </svg>}
            {isSending && <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <path fill="#ffffff" d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
            </svg>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ThreadComponent;
