import React from "react";
import { ESBContextSearchMetadataListItem } from "../types";

interface SourcesProps {
  sources: ESBContextSearchMetadataListItem[];
}

const SourcesComponent: React.FC<SourcesProps> = ({ sources }) => {
  if (sources.length === 0) return (<></>)
  return (
    <div className="group/message rounded-lg mt-3 border border-gray-300 bg-slate-100 rounded-xl p-4">
      <div>
        <ol className="text-xs">
          {sources.map((source, index) => (
            <li key={index} className="my-2 whitespace-nowrap truncate flex">
              <div>
                <div className="flex-none border-solid border-[1px] text-gray-500 border-gray-300 text-center w-6 mr-2">{index+1}</div>
              </div>
              <div>
                <a href={source.metadata.source} target="_blank" rel="noreferrer" className="text-sm text-sky-500 hover:text-sky-700">
                  {source.metadata.file_path || source.metadata.source}
                  <svg className="inline ml-0.5 mb-1" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                </a>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default SourcesComponent;
