import React from "react";
import { Feedback, Message, ESBContextSearchResponse, CustomError, StatusUpdate, ESBContextSearchMetadataListItem } from "../types";
import ToolkitComponent from "./Toolkit";
import SourcesComponent from "./Sources";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { toast } from 'react-toastify';

interface MessageProps {
  messageIndex: number;
  message: Message;
  feedbackList: Feedback[];
  sendFeedback: (feedback: Feedback) => void;
}

function parseMessage(message: Message): ESBContextSearchResponse | string | CustomError {
  try {
    const parsed = JSON.parse(message.content);
    if (parsed != null && parsed.type === 'CustomError') {
      return parsed as CustomError;
    }
    return parsed as ESBContextSearchResponse;
  } catch (e) {
    if (e instanceof SyntaxError) {
      return message.content;
    } else {
      console.error('Error parsing message:', e);
      toast.error('An error occurred while parsing the message.');
      return 'An error occurred while parsing the message.';
    }
  }
}

function parseStatusUpdateArgs(statusUpdate: StatusUpdate): string {
  const statusUpdateInfo = statusUpdate.info as { name: string, arguments: string };
  return JSON.stringify({
    name: statusUpdateInfo.name,
    arguments: JSON.parse(statusUpdateInfo.arguments)
  }, null, 2)
}

const handleCopy = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Text copied to clipboard!');
  } catch (e) {
    toast.error('Failed to copy text to clipboard.');
  }
}

const MessageComponent: React.FC<MessageProps> = ({ messageIndex, message, feedbackList, sendFeedback }) => {
  const [showSources, setShowSources] = React.useState(true);
  const [showInfo, setShowInfo] = React.useState(false);
  let esbContextSourcesList: ESBContextSearchMetadataListItem[] = [];
  let parsedMessage = parseMessage(message);

  if (typeof parsedMessage === 'object' && (parsedMessage as CustomError)?.source != null) {
    const error = parsedMessage as CustomError;
    return (
      <div className="error-message">
        {`Error from ${error.source}: ${error.message}`}
      </div>
    );
  } else if (message.role === 'function') {
    const esbResponse = parsedMessage as ESBContextSearchResponse;
    esbContextSourcesList = esbResponse?.results?.slice(0, 5) ?? [];
    if (esbContextSourcesList.length === 0) return <></>
    parsedMessage = 'Tool sources:';
    
  }
  if (!parsedMessage && !message.statusUpdate) {
    parsedMessage = 'There was an error generating the response. Please try again.';
  }

  if (message.role === 'system') return <></>;
  return (<>
    <div className="flex items-start gap-4">
      <div className="mt-1 bg-slate-200 rounded-full p-5">
        {message.role === 'user' && 
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512">
            <path fill="#555" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
          </svg> 
        }
        {(message.role === 'assistant' || message.role === 'function') && 
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 640 512">
            <path fill="#777" d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z"/>
          </svg> 
        }
      </div>
      <div className="flex-1 w-0"> {/* flex-1 allows the message container to take up the remaining space */}
        <div>
          <div className={`group/message relative rounded-lg border border-gray-300 bg-slate-200 rounded-xl p-4`}>
            <div className="break-all prose max-w-none prose-headings:my-0 prose-a:text-blue-600 prose-pre:my-0 prose-p:my-0 prose-table:my-0">
              {message.statusUpdate !== undefined && (
                <>
                  {message.statusUpdate.status === 'error' ? (
                    <div className='flex items-center text-red-500'>Error occurred while communicating with ESB</div>
                  ) : (
                    <>
                      <div className='flex items-center cursor-pointer underline text-sm text-sky-600' onClick={() => setShowInfo(!showInfo)}>
                        Request is sent to ESB
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${showInfo ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                      {showInfo && <pre>{parseStatusUpdateArgs(message.statusUpdate)}</pre>}
                    </>
                  )}
                </>
              )}
              {parsedMessage !== undefined && (
                <Markdown remarkPlugins={[[remarkGfm, {tablePipeAlign: false}]]}>{parsedMessage as string}</Markdown>
              )}
            </div>
            {message.role !== 'user' && (<>
              <div className="absolute -bottom-3 right-3 hidden group-hover/message:block">
                <ToolkitComponent messageIndex={messageIndex}
                  feedbackList={feedbackList}
                  onCopy={() => handleCopy(parsedMessage as string)}
                  hasSources={esbContextSourcesList.length > 0}
                  showSources={showSources}
                  setShowSources={setShowSources}
                  sendFeedback={sendFeedback}/>
              </div>
            </>)}
          </div>
        </div>
        {showSources && (
          <SourcesComponent sources={esbContextSourcesList} />
        )}
      </div>
    </div>
  </>);
};

export default MessageComponent;
