import React, { useEffect, useState } from "react";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import gravatarUrl from 'gravatar-url';
import { Button } from "react-bootstrap";

function getAvatar(username: string | null) {
  const defaultAvatar = `https://gravatar.com/avatar/00000000000000000000000000000000`
  if (!username) return defaultAvatar
  return gravatarUrl(username)
}

function UserIndicator({ user }: WithAuthenticatorProps) {
  const [userEmail, setUserEmail] = useState<string | null>(null)

  useEffect(() => {
    fetchAuthSession().then((session) => {
      const email = session.tokens?.idToken?.payload?.email?.toString()
      setUserEmail(email ?? null)
    })
  }, [user])

  const avatar = getAvatar(userEmail)

  return (
    <div className="pb-6 pt-3 px-4 flex-none text-sm font-bold text-gray-800">
      {user && (<>
        <img src={avatar} width={30} height={30} alt="" className="inline rounded-full mr-2" />
        {user.username}
      </>)}
      <Button className="text-gray-600 rounded-md float-right" onClick={() => signOut()}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path><path d="M20 12H11M20 12L16 16M20 12L16 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
      </Button>
    </div>
  );
}

export default withAuthenticator(UserIndicator);
