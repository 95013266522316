import { AuthService } from './AuthService'
import { ConversationData, ConversationList, CustomError } from '../types'
import { toast } from 'react-toastify';

interface DispatcherLambdaRequestBody {
  action: string
  username: string
  conversationId?: string
  feedback?: {
    messageIndex?: number
    vote?: string
  }
}

export interface ChatLambdaRequestBody {
  conversationId: string
  username: string
  question: string
}

export class NetworkService {
  chatLambdaUrl: string
  dispatcherLambdaUrl: string
  authService: AuthService

  constructor () {
    this.chatLambdaUrl = process.env.REACT_APP_CHAT_LAMBDA_URL ?? ''
    this.dispatcherLambdaUrl = process.env.REACT_APP_DISPATCHER_LAMBDA_URL ?? ''
    this.authService = new AuthService()
  }

  async listConversations (): Promise<ConversationList[]> {
    return await this.invokeDispatcherLambda({
      action: 'listConversations',
      username: await this.authService.getUsername()
    }) as ConversationList[]
  }

  async getConversationData (conversationId: string): Promise<ConversationData> {
    return await this.invokeDispatcherLambda({
      action: 'getConversationData',
      conversationId,
      username: await this.authService.getUsername()
    }) as ConversationData
  }

  async invokeChatLambda (body: ChatLambdaRequestBody): Promise<ReadableStream<Uint8Array>> {
    const headers = new Headers()
    headers.append('Authorization', await this.authService.getAccessToken())
    headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    }

    const response = await fetch(this.chatLambdaUrl, requestOptions)
    if (response.body == null) throw new Error('invokeChatLambda did not return a stream')
    return response.body
  }

  async invokeDispatcherLambda (body: DispatcherLambdaRequestBody): Promise<unknown> {
    const headers = new Headers()
    headers.append('Authorization', await this.authService.getAccessToken())
    headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    }

    const response = await fetch(this.dispatcherLambdaUrl, requestOptions)
    if (response.status >= 400) {
      let errorMessage = 'An error occured'
      try {
        const error = await response.json()
        if (error.name === 'CustomError') {
          const customError = error as CustomError
          errorMessage = `Error from ${customError.source}: ${customError.message}`
        }
      } catch (error) {
        
      }
      toast.error(errorMessage)
      throw new Error('Error when calling dispatcher lambda')
    }
    return await response.json()
  }
};
