import React, { useEffect, useState } from "react";
import "./App.css";
import ConversationsComponent from "./components/Conversations";
import ThreadComponent from "./components/Thread";
import {
  ConversationList,
  ConversationData,
} from "./types";
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import UserIndicator from "./components/UserIndicator";
import { NetworkService } from "./services/NetworkService";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN || '',
          scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
          redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_URL ?? ''],
          redirectSignOut: [process.env.REACT_APP_COGNITO_REDIRECT_URL ?? ''],
          responseType: 'code',
          providers: ['Google']
        }
      }
    }
  }
});

function createConversation(): ConversationData {
  return {
    conversationId: crypto.randomUUID().toString(),
    timestamp: new Date(),
    messages: [],
    feedback: [],
    // user data should be set on the backend so it cannot be impersonated
    username: '',
    userInfo: {
      email: '',
      givenName: '',
      familyName: '',
      username: '',
      timezone: ''
    }
  }
}

const networkService = new NetworkService()

function App() {
  const [conversationList, setConversationList] = useState<ConversationList[]>([])
  const [conversationData, setConversationData] = useState<ConversationData | null>(createConversation())

  function refreshConversationList() {
    networkService.listConversations().then(setConversationList).catch(() => {});
  }

  function newConversation() {
    setConversationData(createConversation())
  }

  function loadConversation(conversationId: string) {
    setConversationData(null)
    networkService.getConversationData(conversationId).then(setConversationData).catch(() => {});
  }

  useEffect(refreshConversationList, [])

  return (
    <>
    <div className="flex h-screen bg-gray-100">
      <div className="flex flex-col w-80 border-r border-gray-300">
        <ConversationsComponent
          conversations={conversationList}
          newConversation={newConversation}
          loadConversation={loadConversation} />
        <UserIndicator />
      </div>
      <div className="flex-1 border-r border-gray-300 overflow-auto scrollbar-w-2 mb-16">
        {conversationData && (
          <ThreadComponent
            conversationId={conversationData.conversationId}
            messagesList={conversationData.messages}
            feedbackList={conversationData.feedback}
            refreshConversationList={refreshConversationList} />
        )}
      </div>
    </div>
    <ToastContainer />
    </>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true
});
